import {Button, Stack} from '@mui/material';
import DialogLogin from '../../../dialogs/OnBoarding/DialogLogin';
import DialogForgotPassword
  from '../../../dialogs/OnBoarding/DialogForgotPassword';
import {useTranslate} from '@tolgee/react';
import DialogRegister from '../../../dialogs/OnBoarding/DialogRegister';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import DividerVertical from '../Dividers/DividerVertical';
import useOnboardingContext
  from '../../../hooks/onboarding/useOnboardingContext'; 
import { loginEvents, signUpEvents } from '../../../utils/analyticsEvents';

function SiteOnboardingButtons({
                                 size = 'small' || 'medium' || 'large',
                                 variantSignIn = 'outlined',
                                 variantRegister = 'contained',
                                 color = 'contrastText' || 'primary' ||
                                 'secondary',
                                 showDivider = true,
                                 onRegisterSuccess,
                                 onRegisterFailure,
                                 onLoginSuccess = () => {},
                               }) {

  const {t} = useTranslate();
  const isAuthenticated = useIsAuthenticated();

  const {
    isOpenLoginDialog,
    setIsOpenLoginDialog,
    isOpenForgotDialog,
    setIsOpenForgotDialog,
    isOpenRegisterDialog,
    setIsOpenRegisterDialog,
    registerSuccessFn,
    registerSuccessFnParams,
    onRegisterDialogCloseFn,
    onRegisterDialogCloseParams,
    setOnRegisterDialogCloseParams,
    willShowForgotPassword,
    setWillShowForgotPassword,
    willShowLoginDialog,
    setWillShowLoginDialog,
    willShowRegisterDialog,
    setWillShowRegisterDialog,
    preFillEmail,
    setPreFillEmail
  } = useOnboardingContext();

  function handleOpenLoginDialog() {
    setIsOpenLoginDialog(true);
    loginEvents['openLoginPopup']();
  }

  function handleOpenRegisterDialog() {
    setIsOpenRegisterDialog(true); 
  }

  const registerSuccessHandler=()=>{

    if(onRegisterSuccess){
      onRegisterSuccess();
    }

    if(registerSuccessFnParams){
      registerSuccessFn(registerSuccessFnParams);
    }
  }
  
  const onCloseHandler=()=>{

    if(onRegisterDialogCloseParams){
      onRegisterDialogCloseFn(onRegisterDialogCloseParams);
    }
  }
  return (
      <>
        {
            !isAuthenticated &&
            <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
              <Button
                  variant={variantRegister}
                  size={size}
                  onClick={handleOpenRegisterDialog}
                  color={color}
                  sx={{
                    fontWeight: 300,
                  }}
              >
                {t('authFlow.manager.register.heading')}
              </Button>
              {
                  showDivider &&
                  <DividerVertical/>
              }
              <Button
                  variant={variantSignIn}
                  size={size}
                  onClick={handleOpenLoginDialog}
                  color={color}
                  sx={{
                    fontWeight: 300,
                  }}
              >
                {t('authFlow.manager.signin.heading')}
              </Button>
            </Stack>
        }
        <DialogLogin
            isOpenDialog={isOpenLoginDialog}
            setIsOpenDialog={setIsOpenLoginDialog}
            willShowForgotPassword={willShowForgotPassword}
            setWillShowForgotPassword={setWillShowForgotPassword}
            willShowRegisterDialog={willShowRegisterDialog}
            setWillShowRegisterDialog={setWillShowRegisterDialog}
            onLoginSuccess={onLoginSuccess}
            preFillEmail={preFillEmail}
            setPreFillEmail={setPreFillEmail}
            showRegisterDialog={() => {
              setIsOpenRegisterDialog(true);
            }}
            showForgotPasswordDialog={() => {
              setIsOpenForgotDialog(true);
            }}
        />
        <DialogForgotPassword
            isOpenDialog={isOpenForgotDialog}
            setIsOpenDialog={setIsOpenForgotDialog}
            setWillShowForgotPassword={setWillShowForgotPassword}
            willShowLoginDialog={willShowLoginDialog}
            setWillShowLoginDialog={setWillShowLoginDialog}
            showLoginDialog={() => {
              setIsOpenLoginDialog(true);
            }}
        />
        <DialogRegister
            preFillEmail={preFillEmail}
            setPreFillEmail={setPreFillEmail}
            isOpenDialog={isOpenRegisterDialog}
            setIsOpenDialog={setIsOpenRegisterDialog}
            setWillShowRegisterDialog={setWillShowRegisterDialog}
            onRegisterSuccess={registerSuccessHandler}
            onRegisterFailure={onRegisterFailure}
            onClose={onCloseHandler}
        />
      </>
  );
}

export default SiteOnboardingButtons;
