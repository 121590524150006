import {createContext, useEffect, useState} from 'react';

export const OnboardingContext = createContext();

function ProviderOnboarding({children}) {

  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false);
  const [isOpenForgotDialog, setIsOpenForgotDialog] = useState(false);
  const [isOpenRegisterDialog, setIsOpenRegisterDialog] = useState(false);

  const [willShowForgotPassword, setWillShowForgotPassword] = useState(false);
  const [willShowLoginDialog, setWillShowLoginDialog] = useState(false);
  const [willShowRegisterDialog, setWillShowRegisterDialog] = useState(false);
  const [registerSuccessFnParams, setRegisterSuccessFnParams] = useState();
  const [onRegisterDialogCloseParams, setOnRegisterDialogCloseParams] = useState();

  const [preFillEmail,setPreFillEmail] = useState('');

  const registerSuccess=()=>{
    
    if(registerSuccessFnParams){
      registerSuccessFnParams?.successFn();
    }
  }
  
  const onRegisterDialogClose=()=>{
    
    if(onRegisterDialogCloseParams){
      onRegisterDialogCloseParams?.successFn();
    }
  }

  useEffect(() => {
    if (willShowForgotPassword) {
      setIsOpenLoginDialog(false);
    }
  }, [willShowForgotPassword]);

  useEffect(() => {
    if (willShowLoginDialog) {
      setIsOpenForgotDialog(false);
    }
  }, [willShowLoginDialog]);

  useEffect(() => {
    if (willShowRegisterDialog) {
      setIsOpenLoginDialog(false);
    }
  }, [willShowRegisterDialog]);
  
  useEffect(() => {
    if (!isOpenLoginDialog) {
      setPreFillEmail('');
    }
  }, [isOpenLoginDialog]);

  useEffect(()=>{

    if(!isOpenRegisterDialog){
      setPreFillEmail('');
    }
  },[isOpenRegisterDialog])

  return (
      <OnboardingContext.Provider
          value={{
            isOpenLoginDialog,
            setIsOpenLoginDialog,
            isOpenForgotDialog,
            setIsOpenForgotDialog,
            isOpenRegisterDialog,
            setIsOpenRegisterDialog,
            registerSuccessFn:()=>registerSuccess(),
            registerSuccessFnParams,
            setRegisterSuccessFnParams,
            onRegisterDialogCloseFn:()=>onRegisterDialogClose(),
            onRegisterDialogCloseParams,
            setOnRegisterDialogCloseParams,
            willShowForgotPassword,
            setWillShowForgotPassword,
            willShowLoginDialog,
            setWillShowLoginDialog,
            willShowRegisterDialog,
            setWillShowRegisterDialog,
            preFillEmail,
            setPreFillEmail
          }}>
        {children}
      </OnboardingContext.Provider>
  );
}

export default ProviderOnboarding;