import { Record } from "immutable";

const defaultValues = {

  // create page
    matchRange:{
        minRange:0,
        maxRange:10,
        playerLevel:5,
        value:[],
        minDistance:3
    },
    teamSize:null,
    currentLoggedInUser:{
      isRegistered:false,
      canRegister:false,
      canAddPartner:true
    },
    suggestedPlayers:{
      loading:false,
      list:[]
    },
    extras:{
      loading:false,
      list:[]
    },
    submitData:{
      loading:false,
      response:{}
    },
    data:{
      loading:false,
      walletData:[],
      priceData:[]
    },


    // detail page
    singleDetail:{
      loading:false,
      data:-1
    },

    // LISTING

    listing:{
      data:-1,
      loading:false,
      totalPage:0,
      
      filters:{
        sport:'Padel',
        date:'',
        gender:'',
        page:0,
        limit:10
      }
    }

};

// const State = new Record(defaultValues);
// const initialState = new State();

function openMatches(state = defaultValues, action) {
    
  switch (action.type) {
    case 'OPEN_MATCH_CREATE_SET_MATCH_RANGE':
      return {
        ...state,
        matchRange: {...state.matchRange,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_TEAM_SIZE':
      return {
        ...state,
        teamSize: action.payload,
      };
    case 'OPEN_MATCH_CREATE_SET_CURRENT_USER':
      return {
        ...state,
        currentLoggedInUser: {...state.currentLoggedInUser,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_SUGGESTED_PLAYERS':
      return {
        ...state,
        suggestedPlayers: {...state.suggestedPlayers,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_EXTRAS':
      return {
        ...state,
        extras: {...state.extras,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_SUBMIT_DATA':
      return {
        ...state,
        submitData: {...state.submitData,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_DATA':
      return {
        ...state,
        data: {...state.data,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_SINGLE_DETAIL':
      return {
        ...state,
        singleDetail: {...state.singleDetail,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_LISTING_DATA':
      return {
        ...state,
        listing: {...state.listing,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_REFRESH_ALL_STATES':
      return {
        ...defaultValues
      };
    default:
        return state;
  }
}


export const marks = Array.from({length:defaultValues?.matchRange?.maxRange}).map((_,x)=>({value:x,label:x.toString()}))

export default openMatches
