
export const GET_CLUB_TIMING_SUCCESS = 'GET_CLUB_TIMING_SUCCESS';
export const GET_CLUB_TIMING_LOADING = 'GET_CLUB_TIMING_LOADING';
export const GET_CLUB_TIMING_FAILURE = 'GET_CLUB_TIMING_FAILURE';

export const CUSTOMERS_CLUBS_TIMINGS_LIST_SUCCESS = 'CUSTOMERS_CLUBS_TIMINGS_LIST_SUCCESS';
export const CUSTOMERS_CLUBS_TIMINGS_LIST_LOADING = 'CUSTOMERS_CLUBS_TIMINGS_LIST_LOADING';
export const CUSTOMERS_CLUBS_TIMINGS_LIST_FAILURE = 'CUSTOMERS_CLUBS_TIMINGS_LIST_FAILURE';

export const CLUBS_AMENITIES_LIST_SUCCESS = 'CLUBS_AMENITIES_LIST_SUCCESS';
export const CLUBS_AMENITIES_LOADING = 'CLUBS_AMENITIES_LOADING';
export const CLUBS_AMENITIES_LIST_FAILURE = 'CLUBS_AMENITIES_LIST_FAILURE';

export const GET_COURT_SUCCESS = 'GET_COURT_SUCCESS';
export const GET_COURT_LOADING = 'GET_COURT_LOADING';
export const GET_COURT_FAILURE = 'GET_COURT_FAILURE';

export const BOOKINGS_TOKEN_GET_SUCCESS = 'BOOKINGS_TOKEN_GET_SUCCESS';
export const BOOKINGS_TOKEN_GET_LOADING = 'BOOKINGS_TOKEN_GET_LOADING';
export const BOOKINGS_TOKEN_GET_FAILURE = 'BOOKINGS_TOKEN_GET_FAILURE';

export const CUSTOMER_BOOKINGS_LIST_GET_SUCCESS = 'CUSTOMER_BOOKINGS_LIST_GET_SUCCESS';
export const CUSTOMER_BOOKINGS_LIST_GET_LOADING = 'CUSTOMER_BOOKINGS_LIST_GET_LOADING';
export const CUSTOMER_BOOKINGS_LIST_GET_FAILURE = 'CUSTOMER_BOOKINGS_LIST_GET_FAILURE';


export const CUSTOMERS_BOOKINGS_SINGLE_GET_SUCCESS = 'CUSTOMERS_BOOKINGS_SINGLE_GET_SUCCESS';
export const CUSTOMERS_BOOKINGS_SINGLE_GET_LOADING = 'CUSTOMERS_BOOKINGS_SINGLE_GET_LOADING';
export const CUSTOMERS_BOOKINGS_SINGLE_GET_FAILURE = 'CUSTOMERS_BOOKINGS_SINGLE_GET_FAILURE';

export const CUSTOMERS_BOOKINGS_CREATE_POST_SUCCESS = 'CUSTOMERS_BOOKINGS_CREATE_POST_SUCCESS';
export const CUSTOMERS_BOOKINGS_CREATE_POST_LOADING = 'CUSTOMERS_BOOKINGS_CREATE_POST_LOADING';
export const CUSTOMERS_BOOKINGS_CREATE_POST_FAILURE = 'CUSTOMERS_BOOKINGS_CREATE_POST_FAILURE';

//Policies
export const POLICIES_SUCCESS = 'POLICIES_SUCCESS';
export const POLICIES_LOADING = 'POLICIES_LOADING';
export const POLICIES_FAILURE = 'POLICIES_FAILURE';


// BOOKINGS CANCEL
export const BOOKINGS_CANCEL_POST_SUCCESS = 'BOOKINGS_CANCEL_POST_SUCCESS';
export const BOOKINGS_CANCEL_POST_LOADING = 'BOOKINGS_CANCEL_POST_LOADING';
export const BOOKINGS_CANCEL_POST_FAILURE = 'BOOKINGS_CANCEL_POST_FAILURE';

export const CUSTOMERS_BOOKINGS_CANCEL_POST_SUCCESS = 'CUSTOMERS_BOOKINGS_CANCEL_POST_SUCCESS';
export const CUSTOMERS_BOOKINGS_CANCEL_POST_LOADING = 'CUSTOMERS_BOOKINGS_CANCEL_POST_LOADING';
export const CUSTOMERS_BOOKINGS_CANCEL_POST_FAILURE = 'CUSTOMERS_BOOKINGS_CANCEL_POST_FAILURE';

export const CUSTOMERS_CLUBS_EXTRAS_lIST_GET_SUCCESS = 'CUSTOMERS_CLUBS_EXTRAS_lIST_GET_SUCCESS';
export const CUSTOMERS_CLUBS_EXTRAS_lIST_GET_LOADING = 'CUSTOMERS_CLUBS_EXTRAS_lIST_GET_LOADING';
export const CUSTOMERS_CLUBS_EXTRAS_lIST_GET_FAILURE = 'CUSTOMERS_CLUBS_EXTRAS_lIST_GET_FAILURE';

export const CUSTOMERS_BOOKINGS_CALCULATE_PRICE_POST_SUCCESS = 'CUSTOMERS_BOOKINGS_CALCULATE_PRICE_POST_SUCCESS';
export const CUSTOMERS_BOOKINGS_CALCULATE_PRICE_POST_LOADING = 'CUSTOMERS_BOOKINGS_CALCULATE_PRICE_POST_LOADING';
export const CUSTOMERS_BOOKINGS_CALCULATE_PRICE_POST_FAILURE = 'CUSTOMERS_BOOKINGS_CALCULATE_PRICE_POST_FAILURE';

// AVAILABLE COURTS
export const BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS = 'BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS';
export const BOOKINGS_AVAILABLE_COURTS_GET_LOADING = 'BOOKINGS_AVAILABLE_COURTS_GET_LOADING';
export const BOOKINGS_AVAILABLE_COURTS_GET_FAILURE = 'BOOKINGS_AVAILABLE_COURTS_GET_FAILURE';

//EVENTS
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
export const EVENTS_LOADING = 'EVENTS_LOADING';
export const EVENTS_FAILURE = 'EVENTS_FAILURE';
// PLAYERS
export const BOOKINGS_CUSTOMERS_GET_SUCCESS = 'BOOKINGS_CUSTOMERS_GET_SUCCESS';
export const BOOKINGS_CUSTOMERS_GET_LOADING = 'BOOKINGS_CUSTOMERS_GET_LOADING';
export const BOOKINGS_CUSTOMERS_GET_FAILURE = 'BOOKINGS_CUSTOMERS_GET_FAILURE';

// PLAYERS
export const CUSTOMERS_BOOKINGS_CUSTOMERS_GET_SUCCESS = 'CUSTOMERS_BOOKINGS_CUSTOMERS_GET_SUCCESS';
export const CUSTOMERS_BOOKINGS_CUSTOMERS_GET_LOADING = 'CUSTOMERS_BOOKINGS_CUSTOMERS_GET_LOADING';
export const CUSTOMERS_BOOKINGS_CUSTOMERS_GET_FAILURE = 'CUSTOMERS_BOOKINGS_CUSTOMERS_GET_FAILURE';


export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_LOADING = 'EDIT_CUSTOMER_LOADING';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';


export const CUSTOMER_BOOKINGS_SELF_GET_SUCCESS = 'CUSTOMER_BOOKINGS_SELF_GET_SUCCESS';
export const CUSTOMER_BOOKINGS_SELF_GET_LOADING = 'CUSTOMER_BOOKINGS_SELF_GET_LOADING';
export const CUSTOMER_BOOKINGS_SELF_GET_FAILURE = 'CUSTOMER_BOOKINGS_SELF_GET_FAILURE';

export const GET_PLAYER_BYID_SUCCESS = 'GET_PLAYER_BYID_SUCCESS';
export const GET_PLAYER_BYID_LOADING = 'GET_PLAYER_BYID_LOADING';
export const GET_PLAYER_BYID_FAILURE = 'GET_PLAYER_BYID_FAILURE';

//
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_LOADING = 'VERIFY_EMAIL_LOADING';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

//search on player side
export const SEARCH_DATA_FOR_PLAYER_SUCCESS = 'SEARCH_DATA_FOR_PLAYER_SUCCESS';
export const SEARCH_DATA_FOR_PLAYER_LOADING = 'SEARCH_DATA_FOR_PLAYER_LOADING';
export const SEARCH_DATA_FOR_PLAYER_FAILURE = 'SEARCH_DATA_FOR_PLAYER_FAILURE';

export const COURT_TYPE_FOR_PLAYER_SUCCESS = 'COURT_TYPE_FOR_PLAYER_SUCCESS';
export const COURT_TYPE_FOR_PLAYER_LOADING = 'COURT_TYPE_FOR_PLAYER_LOADING';
export const COURT_TYPE_FOR_PLAYER_FAILURE = 'COURT_TYPE_FOR_PLAYER_FAILURE';

export const VALIDATE_PLAYER_PASSWORD_SUCCESS = 'VALIDATE_PLAYER_PASSWORD_SUCCESS';
export const VALIDATE_PLAYER_PASSWORD_LOADING = 'VALIDATE_PLAYER_PASSWORD_LOADING';
export const VALIDATE_PLAYER_PASSWORD_FAILURE = 'VALIDATE_PLAYER_PASSWORD_FAILURE';

export const CHANGE_PLAYER_PASSWORD_SUCCESS = 'CHANGE_PLAYER_PASSWORD_SUCCESS';
export const CHANGE_PLAYER_PASSWORD_LOADING = 'CHANGE_PLAYER_PASSWORD_LOADING';
export const CHANGE_PLAYER_PASSWORD_FAILURE = 'CHANGE_PLAYER_PASSWORD_FAILURE';

//COUNTRY
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_LOADING = 'GET_COUNTRY_LOADING';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const CUSTOMER_UPCOMING_BOOKING_LOADING = 'CUSTOMER_UPCOMING_BOOKING_LOADING';
export const CUSTOMER_UPCOMING_BOOKING_SUCCESS = 'CUSTOMER_UPCOMING_BOOKING_SUCCESS';
export const CUSTOMER_UPCOMING_BOOKING_FAILURE = 'CUSTOMER_UPCOMING_BOOKING_FAILURE';

export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_LOADING = 'GET_CITIES_LOADING';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';

//SPORTS
export const GET_SPORTS_SUCCESS = 'GET_SPORTS_SUCCESS';
export const GET_SPORTS_LOADING = 'GET_SPORTS_LOADING';
export const GET_SPORTS_FAILURE = 'GET_SPORTS_FAILURE';

//user
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

//REDUX
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';

export const SHOW_VERSION_MISMATCH_POPUP = 'VERSION_UPDATED'
