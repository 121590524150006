import {useSnackbar} from 'notistack';
import {Controller, useForm} from 'react-hook-form';
import {useState} from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Key,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {blue} from '@mui/material/colors';
import {useTranslate} from '@tolgee/react';

function PagePasswordProtected({setHasAccess}) {

  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const {t} = useTranslate();

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm();
  const watched = watch();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function handleLoginSubmit() {
    handleSubmit(async ({password}) => {
          if (watched.password !== 'Springstreet$$') {
            enqueueSnackbar(t('global.alerts.error.incorrectPassword'), {variant: 'error'});
          } else {
            localStorage.setItem('masterAccess', true);
            setHasAccess(true);
          }
        },
        (errors) => {
          enqueueSnackbar(t('global.alerts.error.enterPassword'), {variant: 'error'});
        },
    )();
  }

  function handleTogglePasswordVisibility() {
    setIsPasswordVisible((cur) => !cur);
  }

  function handleEnterKeySubmit(e) {
    e.key === 'Enter' && handleLoginSubmit();
  }

  return (
      <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <Box
            sx={{
              my: 2,
              mx: 2,
              maxWidth: '448px',
              width: '100%',
              borderRadius: '12px',
              border: `1px solid ${theme.palette.divider}`,
              padding: 3,
            }}
        >
          <Stack alignItems={'flex-start'}>
            <Box
                sx={{
                  backgroundColor: blue[50],
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px',
                  borderRadius: '50%',
                  mb: 4,
                }}
            >
              <Key fontSize={'medium'} color={'primary'}/>

            </Box>

            <Typography variant={'h5'}>
              {t('PagePasswordProtect.title')}
            </Typography>
            <Typography variant={'body1'} color={'text.secondary'} mb={3}>
              {t('PagePasswordProtect.description')}
            </Typography>
            <Controller
                name={'password'}
                rules={{
                  required: {
                    value: true,
                    message: t('global.fields.validation.required.password'),
                  },
                }}
                control={control}
                render={({field}) => {
                  return <TextField
                      required
                      fullWidth
                      label={t('global.fields.labels.password')}
                      size="medium"
                      placeholder={t('global.fields.placeholder.enterPassword')}
                      type={isPasswordVisible ? 'text' : 'password'}
                      error={!!errors['password']}
                      helperText={errors['password'] &&
                          errors['password'].message}
                      onKeyDown={handleEnterKeySubmit}
                      InputProps={{
                        endAdornment: <InputAdornment
                            position="end"
                            sx={{cursor: 'pointer'}}
                            onClick={handleTogglePasswordVisibility}
                        >
                          {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                        </InputAdornment>,
                      }}
                      {...field}
                  />;
                }}
            />
            <Button
                fullWidth
                size={'large'}
                variant={'contained'}
                onClick={handleLoginSubmit}
                sx={{mt: 2}}
            >
              {t('global.buttons.actions.continue')}
            </Button>


          </Stack>

        </Box>

      </Box>
  );
}

export default PagePasswordProtected;